<template>
    <el-container class="page-container">

        <el-main class="page-main">

            <el-form label-position='left' label-width="60px" :model="query" ref="searchForm">
              <el-row :gutter="20">
                <el-col :xs="12" :sm="12" :md="8" :lg="6">
                    <el-form-item  label-width="40px"  label="状态" prop="status">
                        <el-select v-model="query.status" placeholder="请选择" style="width:100%">
                            <el-option label="发起盘点" :value="1"></el-option>
                            <el-option label="审核通过" :value="2"></el-option>
                            <el-option label="审核未通过" :value="3"></el-option>
                             <el-option label="已盘点" :value="4"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
            
                <el-col :xs="12" :sm="12" :md="8" :lg="6">
                  <el-form-item label-width="70px" label="创建时间" prop="time">
                    <el-date-picker
                        v-model="query.time"
                        type="daterange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd"
                        style="width: auto"
                    >
                    </el-date-picker>
                  </el-form-item>
                </el-col>
                <el-col :xs="12" :sm="12" :md="8" :lg="6">
                  <el-form-item label-width="0">
                    <el-button type="primary" @click="handleSearch">
                      <i class="cw-icon cw-icon-sousuo"></i>搜索
                    </el-button>
                    <el-button type="primary" plain @click="clearSearch">
                        <i class="cw-icon cw-icon-zhongzhi"></i>重置
                    </el-button>
                  </el-form-item>
                </el-col>
              </el-row>

            </el-form>
            <el-row class="row-btn-group">
                <el-button type="primary" @click="handleCheck()">
                    <i class="cw-icon cw-icon-tianjia"></i>发起盘点
                </el-button>
            </el-row>
            <el-table
                    :data="tableData"
                    style="width: 100%"
                    border
                    ref="table"

            >
                <el-table-column type="expand">
                    <template slot-scope="props">
                        <el-table
                          :data="props.row.checkDetailList"
                        >
                            <el-table-column
                                    label="物料名称"
                                    prop="name">
                            </el-table-column>
                            <el-table-column
                                    label="当前库存"
                                    prop="old_store">
                            </el-table-column>
                            <el-table-column
                                    label="实际库存"
                                    prop="new_store">
                            </el-table-column>
                            <el-table-column
                                    label="物料单位"
                                    prop="unit">
                            </el-table-column>
                        </el-table>
                    </template>
                </el-table-column>
                <el-table-column
                        label="ID"
                        prop="id">
                </el-table-column>
                <el-table-column
                        label="状态"
                        prop="status"
                        :formatter="formatterStatus"
                        >
                </el-table-column>
                <el-table-column
                        label="创建时间"
                        prop="createTimeStr">
                </el-table-column>
                <el-table-column
                        label="审核时间"
                        prop="updateTimeStr">
                </el-table-column>
                <el-table-column
                        label="发起人"
                        prop="initUser">
                </el-table-column>
                <el-table-column
                        label="审核人"
                        prop="sysUser">
                </el-table-column>

                <el-table-column
                        prop="id"
                        label="操作"
                        fixed="right"
                        width="115">
                    <template slot-scope="scope">
                        <el-dropdown @command="dropdownCommand">
                            <el-button size="mini">
                                <i class="el-icon-setting"></i>
                                操作
                                <span class="el-icon-arrow-down"></span>
                            </el-button>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item v-if="scope.row.status==4" :command="{type:'verify',row:scope.row}">
                                   审核盘点
                                </el-dropdown-item>
                                <el-dropdown-item v-if="scope.row.status==1" :command="{type:'init',row:scope.row}">
                                   开始盘点
                                </el-dropdown-item>
                                <el-dropdown-item v-if="scope.row.status==3" :command="{type:'init',row:scope.row}">
                                   重新盘点
                                </el-dropdown-item>
                                <el-dropdown-item :command="{type:'edit',row:scope.row}">
                                   详情
                                </el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </template>
                </el-table-column>
            </el-table>
            <br>
            <el-pagination
                    background
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="pageInfo.pageNumber"
                    :page-sizes="[10, 20, 30, 40]"
                    :page-size="pageInfo.pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="pageInfo.totalCount">
            </el-pagination>
        </el-main>
        <el-dialog title="开始盘点" :visible.sync="formDalogVisible" :fullscreen="true">
          <foodList @doAdd="doAdd" @closeModul="closeModul" :sendData="sendData"></foodList>
        </el-dialog>
        <el-dialog title="审核盘点" :visible.sync="auditCheckVisible" width="500px">
          <el-form :model="formData" ref="editForm" :rules="formRules" label-position="right" label-width="80px" style="width: 100%">
            <el-form-item prop="password" label="支付密码">
                <el-input placeholder="支付密码"
                          v-model="formData.password"
                          auto-complete="off" tabindex="4" type="password" style="width: 100%"></el-input>
            </el-form-item>
            <el-form-item >
                <el-button type="primary" :loading="loading" @click="auditCheck(2)">通 过</el-button>
                <el-button @click="auditCheck(3)">驳 回</el-button>
            </el-form-item>

        </el-form>
        </el-dialog>
    </el-container>

</template>

<script>
import _ from 'lodash'
import request from '@/utils/request'
import { addFoodShoppingList, getFoodMaterialList,foodStockData,auditCheck,initCheck} from '@/api/foodManager'
import foodList from '@/views/foodsManage/components/foodList'
import { initDay } from '@/utils'
export default {
    name: "foodStock",
    components:{foodList},
    data() {
        return {
            loading: false,
            tableData: [],
            formDalogVisible: false,
            query: {
              time:'',
              status:"",
            },
            pageInfo:{
                pageNumber:1,
                pageSize:10,
                pageOrderName:'create_time',
                pageOrderBy:'DESC',
                totalCount:null,
            },
            initID:null,
            sendData:{
                config:[],
            },
            auditCheckVisible:false,
            formData:{
                password:''
            },
            formRules:{
                 password: [
                    {required: true,message: '请输入支付密码',trigger: 'blur'}
                ],
            }
        }
    },

    methods: {
        async dropdownCommand(command) {//监听下拉框事件
            if ("init" === command.type) {
                this.formDalogVisible = true;//打开编辑窗口
                this.sendData.config=JSON.parse(JSON.stringify(command.row.checkDetailList));
                this.initID=command.row.id;
            }
            if ("verify" === command.type) {
                this.initID=command.row.id;
                this.auditCheckVisible=true;
                if(this.$refs.editForm)
                this.$refs.editForm.resetFields();
            }
            if('edit'===command.type){
                let $table = this.$refs.table;
                $table.toggleRowExpansion(command.row,true);
            }
        },
        async auditCheck(status){
            this.$refs["editForm"].validate(async (valid) => {
                if(valid){
                    try{
                        let data=await auditCheck({password:this.formData.password,status,id:this.initID});
                        this.$message({
                                type: status==2?'success':'error',
                                message: data.msg
                        });
                        this.getData();
                        this.auditCheckVisible=false;
                    }catch(e){

                    }
                }
            })
        },
        //发起盘点
        async handleCheck(){
            try {
                await this.$confirm('确定发起盘点吗', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                })

                let data=await initCheck({init:0});
                this.$message({
                    message: data.msg,
                    type: 'success'
                });
                this.getData();
            } catch (e) {
            }
        },
        formatterStatus(row){
            if(row.status==1){
                return "发起盘点";
            }else if(row.status==2){
                return "审核通过";
            }else if(row.status==3){
                return "审核未通过";
            }else{
                return "已盘点";
            }
        },
        handleSizeChange(val) {
            this.pageInfo.pageSize = val;
            this.pageInfo.page = 1;
            this.getData();
        },
        handleCurrentChange(val) {
            this.pageInfo.pageNumber = val;
            this.getData();
        },
        handleSearch() {
            this.pageInfo.pageNumber = 1;
            this.getData()
        },
        clearSearch(){
            this.query={
                status:'',
                time:'',
            };
            this.handleSearch();
        },
        async getData() {
            try {
                 let obj=!this.query.time?{}:{createTimeStart:this.query.time[0]+' 00:00:00',createTimeEnd:this.query.time[1]+' 23:59:59'};
                 let query = Object.assign({},{status:this.query.status},obj);
                 let querys = Object.assign({}, this.pageInfo,query);
                 let formData = {};
                 for (let i in querys) {
                     if (query[i] !== '' && i !== 'totalCount' && query[i] !== -1) {
                       formData[i] = querys[i]
                     }
                 }
                let {data} = await foodStockData(formData);
                this.tableData=data.list;
                this.pageInfo.totalCount=data.totalCount;
            } catch (e) {

            }
        },
        async doAdd(formData) {
            // console.log(313,formData)
          try {
            this.formDalogVisible = false;
            let foodMaterialCheck=JSON.stringify({'detailList':formData});
            await initCheck({foodMaterialCheck,id:this.initID,init:1,});
            this.$message({
                type: 'success',
                message: '操作成功!'
            });
            
            this.getData();   
          }catch (e) {
          
         }
        },
        closeModul() {
          this.formDalogVisible=false;
        },
        
    },
    mounted() {
        this.getData();
    }
}
</script>

<style lang="less">
.adsdia {
    .el-upload {
        height: 100px;
        width: 100px !important;
        line-height: 100px;
    }
}

.btn-group {
    button {
        margin-left: 20px;
    }

}

.el-input--prefix .el-input__inner {
    padding-left: 46px;
}

.el-input__prefix {
    left: 15px;
    color: #303133;

    i {
        font-style: normal;
    }
}

.icon-cursor {
    cursor: pointer;
    margin-right: 10px;

    &:hover {
        color: #348EED;
    }
}

.avatar-uploader {
    .el-upload {
        border: 1px solid #DCDFE6;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        width: 72px;
        display: block;

        &:hover {
            border-color: #409EFF;
        }
    }

    .cw-icon-tupian {
        font-size: 24px;
        color: #c0c4cc;
        width: 72px;
        height: 72px;
        line-height: 72px;
        text-align: center;
    }

    .avatar {
        width: 72px;
        height: 72px;
        display: block;
    }
}
</style>
