<template>
    <div class="dialog-container">
      <el-form :model="sendData" ref="configForm">
        <el-table
            ref="multipleTable"
            :data="sendData.config"
            tooltip-effect="dark"
            style="width: 100%"
        >
          <el-table-column
              label="名称"
              prop="name"
              show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
             label="当前库存"
             prop="old_store">
          </el-table-column>
          <el-table-column
              prop="new_store"
              label="实际库存"
              show-overflow-tooltip>
            <template slot-scope="scope">
              <el-form-item
                  class="formitem"
                  :prop="'config.'+scope.$index+'.new_store'"
                  :rules="[
                            {pattern: /^(-)*(([0-9]+)|([0-9]+\.[0-9]{1,2}))$/,message: '最多只能输入2位小数',trigger: ['blur','change']}
                        ]"
              >
                <el-input v-model="scope.row.new_store" placeholder="实际剩余"></el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column
              label="物料单位"
              prop="unit">
          </el-table-column>
        </el-table>
      </el-form>
      <div class="flex flex-direction-row-reverse btn-group" style="margin-top: 20px">
        <el-button type="primary" @click="doAdd">确 定</el-button>
        <el-button @click="closeModul">取 消</el-button>
      </div>
    </div>
</template>

<script>
export default {
    name: "addFoods",
    props:{
      sendData:{},
    },
    data() {
        return {
          
        }
    },
    methods: {
      doAdd(){
        this.$refs.configForm.validate(async (valid) => {
          if(valid){
            try {
              await this.$confirm('确定提交该次库存盘点吗？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
              })
              let formData=this.sendData.config.filter(curr=>curr.new_store);

              this.$emit('doAdd',formData)
            }catch (e) {

            }
          }
        });

      },
      closeModul(){
        this.$emit('closeModul')
      },
    },
    mounted() {
    }
}
</script>
<style scoped lang="less">
.dialog-container {
    .avatar-uploader {
        display: inline-block;
        width: 146px;

        /deep/ .el-upload {
            width: 146px;
            height: 146px;
            line-height: 146px;
            border: 1px dashed #d9d9d9;
            border-radius: 6px;
            cursor: pointer;
            position: relative;
            overflow: hidden;
        }

        .el-upload:hover {
            border-color: #409EFF;
        }

        .avatar-uploader-icon {
            font-size: 28px;
            color: #8c939d;
            width: 146px;
            height: 146px;
            line-height: 146px;
            text-align: center;
            border: 1px dashed #dedede;
            border-radius: 10px;
        }

        .avatar {
            width: 146px;
            height: 146px;
            display: block;
        }
    }

}
.formitem{
  margin-bottom: 0!important;
}
</style>

